var generic = generic || {};
var site = site || {};
//Fix for IOS4 Menu navigation and Sign in popup scrolling
var navScroll, popupScroll;
$(window).load(function() {
  if (generic.env.isIOS4) {
    navScroll = new IScroll('.global-header-navigation-elc-nodeblock', { mouseWheel: true, click: true, tap: true});
    popupScroll = new IScroll('#colorbox', { mouseWheel: true, click: true, tap: true});
  }
});

(function($) {
  Drupal.behaviors.ELBMobile = {
    attach: function(context, settings) {

      /**
       * copied from estee_base/js/shared/loyalty_offer.js
       * Helper function to get the raw contents of an JS inline template, and optionally interpolate
       * the html using Mustache
       */
      getTemplateContent = function(key, data) {
        var undef;

        // Sanitize the incoming data
        data = data || false;

        // Get the html content of the template
        var html = $("script.inline-template[path='" + key + "']").html();

        if (!html || html.length === 0) {
          return $();
        }

        // If there's no data object passed in, then just return a basic jquery element
        if (data == false || !$.isFunction(Mustache.render)) {
          return $(html);
        }

        // We have html and data, which means we should use Mustache to render the output
        return $(Mustache.render(html, data));
      };

      // don't show loyalty pop up on some URLs - these are set in the CMS
      var template = getTemplateContent('loyalty_disable_urls_uk');
      var excludedURLs = template.val();
      var excludedURLCheck = template.hasClass('js-excluded-urls');

      if( excludedURLCheck && excludedURLs.length > 0 ) {
          var URLarray = excludedURLs ? excludedURLs.split(',') : [];
          var pathURL = window.location.pathname;

          // if we're on one of the pages, set popup cookie
          if(URLarray.length > 0 && URLarray.indexOf(pathURL) !== -1){
              $.cookie('elist15', '1', {
                  path: '/'
              });
          }
      }

      $('.spp-product__share').click(function() {
        var url = document.documentURI;
        var title = document.title;

        var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURI(title);
        var facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(url) + '&amp;t=' + encodeURI(title);

        var img = $('#main img').first().attr('src');
        var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=' + encodeURI(title);

        var socialHTML = '<div class="social-share"><h4 class="title">Share</h4> <ul> <li><a class="social-share__link facebook" href="' + facebook_url + '" target="_blank">Facebook</a></li> <li><a class="social-share__link twitter" href="' + twitter_url + '" target="_blank">Twitter</a></li> <li><a class="social-share__link pinterest" href="' + pinterest_url + '" target="_blank">Pinterest</a></li> </ul> </div> ';
        $.colorbox({
          html: socialHTML,
          className: 'colorbox--social-share',
          width: '100%',
          height: '100%'
        });
        return false;
      });

      var applySocialMediaOffer = function(offerCode) {
        var site = site || {};
        var signedIn = site.userInfoCookie.getValue('signed_in');
        var isLoyaltyMember = site.userInfoCookie.getValue('is_loyalty_member');

        // if the user is signed in and a loyalty member apply the social media offer
        if (signedIn && isLoyaltyMember) {
          var paramObj = {'offer_code': offerCode, 'do_not_defer_messages': 1};

          generic.jsonrpc.fetch({
            method: 'offers.apply',
            params: [paramObj]
          });
        }
      };

      if ($('.product-full__personal').find('.social-share-icons').length > 0) {
        var signedIn = site.userInfoCookie.getValue('signed_in');
        var isLoyaltyMember = site.userInfoCookie.getValue('is_loyalty_member');
        var url = document.documentURI;
        var title = document.title;

        // clean up urlIn
        url = url.replace(/#.*/, '');

        var extra_title = title.split('|', 1);
        extra_title = $.trim(extra_title);
        var shareCopyLine1 = "What's happening?";
        var shareCopyLine2 = 'Check out ' + extra_title + ' from @Esteelauder';
        if (signedIn && isLoyaltyMember) {
          shareCopyLine1 = 'Check out ' + extra_title + ' from @EsteeLauder! (I’m an E-Lister & may receive loyalty points for sharing.)';
        }

        var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURIComponent(shareCopyLine1) + '%0A%0A' + encodeURIComponent(shareCopyLine2);

        var facebook_url = 'https://www.facebook.com/dialog/feed?app_id=87741124305&link=' + encodeURI(url) + '&description=' + encodeURIComponent(shareCopyLine1) + '%0A' + encodeURIComponent(shareCopyLine2);

        var img = $('#main img').first().attr('src');
        var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=' + encodeURIComponent(shareCopyLine1) + '%0A%0A' + encodeURIComponent(shareCopyLine2);

        var whatsapp_tracking = url + '?utm_source=Whatsapp&utm_campaign=WhatsappShare&utm_medium=social&utm_content=' + $('.product-full__personal .social-share-icons .whatsapp').attr('data-product-id');
        var whatsapp_url = 'whatsapp://send?text=' + encodeURIComponent(shareCopyLine1) + '%0A%0A' + encodeURIComponent(shareCopyLine2) + '%0A%0A' + encodeURIComponent(whatsapp_tracking);

        if (signedIn && isLoyaltyMember) {
          twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURIComponent(shareCopyLine1);
          facebook_url = 'https://www.facebook.com/dialog/feed?app_id=87741124305&link=' + encodeURI(url) + '&description=' + encodeURIComponent(shareCopyLine1);
          pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=' + encodeURIComponent(shareCopyLine1);
          whatsapp_url = 'whatsapp://send?text=' + encodeURIComponent(shareCopyLine1) + '%0A%0A' + encodeURIComponent(whatsapp_tracking);
        }

        $('.product-full__personal .social-share-icons .facebook').attr('href', facebook_url).attr('offer_code', 'lyl_social_fb').click(function() {
          applySocialMediaOffer($(this).attr('offer_code'));
        });
        $('.product-full__personal .social-share-icons .twitter').attr('href', twitter_url).attr('offer_code', 'lyl_social_twitter').click(function() {
          applySocialMediaOffer($(this).attr('offer_code'));
        });
        $('.product-full__personal .social-share-icons .pinterest').attr('href', pinterest_url).attr('offer_code', 'lyl_social_pinterest').click(function() {
          applySocialMediaOffer($(this).attr('offer_code'));
        });
        $('.product-full__personal .social-share-icons .whatsapp').attr('href', whatsapp_url).attr('offer_code', 'lyl_social_whatsapp').click(function() {
          applySocialMediaOffer($(this).attr('offer_code'));
        });
      }

      $('.elc-user-state-anonymous .menu-reference--loyalty').click(function(event) {
        event.preventDefault();
        window.location.href = '/loyalty_ldg';
      });
    }
  };

  Drupal.behaviors.ELBIOS4StickyFooter = {
    attach: function(context, settings) {
      if (generic.env.isIOS4) {
        var $stickyFooter = $('.page-sticky-footer');
        var $stickyHeader = $('.page-header');
        var $footer = $('.page-footer');
        $stickyFooter.addClass('mobile_sticky');
        $stickyFooter.css('top', window.innerHeight + window.scrollY - 30 + 'px');
        $footer.css('position', 'static');
        $stickyHeader.addClass('mobile_sticky');

        $(document).bind('touchstart', function(e) {
          // to avoid hiding the footer if it is node 'touched' by the user
          if (!$(e.target).parents('.page-sticky-footer').length > 0) {
            $stickyFooter.hide();
          }
          // to avoid hiding the header if it is node 'touched' by the user
          if (!$(e.target).parents('.page-header').length > 0) {
            $stickyHeader.hide();
          }
        });

        $(document).bind('touchend', function() {
          _.defer(function() {
            // handles waiting until snapback scrolling on mobile devices has finished
            $stickyFooter.show();
            $stickyHeader.show();
          });
        });

        $(document).bind('scroll', function(e) {
          $stickyFooter.css('top', window.innerHeight + window.scrollY - 30 + 'px');
          $stickyHeader.css('top', $(window).scrollTop() + 'px');
        });
      }
    }
  };

  Drupal.behaviors.ELBMobileStickyFooter = {
    attach: function(context, settings) {
      var $stickyFooter = $('.page-sticky-footer', context);
      var $stickyFooterLinks = $('a', $stickyFooter);
      var $myFeedText = $('.sticky-footer__my-feed-link', $stickyFooter);
      var $footer = $('.page-footer', context);
      var site = site || {};
      var signedIn = false;
      if (typeof site.userInfoCookie != 'undefined') {
        signedIn = parseInt(site.userInfoCookie.getValue('signed_in'), 10);
      }

      function _scrollToFooter() {
        $(window).scrollTo($footer);
      }

      function _populateMyFeedCount() {
        // @TODO: hook up properly
        var itemCount = 6;
        if (itemCount) {
          $myFeedText.append('<span class="item-count"> (' + itemCount + ')</span>');
        }
      }

      $stickyFooterLinks.on('click', function(event) {
        if ($('.sticky-footer__help-link', this).length) {
          event.preventDefault();
          _scrollToFooter();
        }
      });

      _populateMyFeedCount();
    }
  };

  /**
   * confirm wishlist addition
   */
  Drupal.behaviors.ELBMobile_wishlistConfirm = {
    attach: function(context, settings) {
      if (typeof site.wishlistConfirm != 'undefined') {
        site.wishlistConfirm.init();
      }
    }
  };

  /**
   * estee edit moodboard slideshow overlay launcher
   */
  Drupal.behaviors.mb_slideshow = {
    attach: function(context, settings) {
      // share link vars
      var url = document.documentURI;
      var title = document.title;

      var twitter_url = 'http://twitter.com/intent/tweet?url=' + encodeURI(url) + '&amp;text=' + encodeURI(title);
      $('.mb-slide-share__link.twitter').attr('href', twitter_url);

      var facebook_url = 'http://www.facebook.com/sharer.php?u=' + encodeURI(url) + '&amp;t=' + encodeURI(title);
      $('.mb-slide-share__link.facebook').attr('href', facebook_url);

      // @todo debug this is grabbing the first image/first slide only
      var img = $('.mb-slideshow__slide img').attr('src');
      var pinterest_url = 'http://pinterest.com/pin/create/button/?url=' + encodeURI(url) + '&amp;media=' + encodeURI(window.location.protocol) + '//' + encodeURI(window.location.host) + img + '&amp;description=' + encodeURI(title);
      $('.mb-slide-share__link.pinterest').attr('href', pinterest_url);

      // // apply flexslider to the main node for previewing purposes
      // if( $('.formatter-mb-slideshow .flexslider').length > 0 ){
      //   $('.formatter-mb-slideshow .flexslider').flexslider({
      //     animation: "fade",
      //     slideshow: false,
      //     controlNav: false
      //   });
      // }
    } // attach
  };

  /**
   * youtube video overlay launcher
   */
  Drupal.behaviors.ELBMobileyoutube = {
    attach: function(context, settings) {
    // send mobile to a different place
      var $isEsteeEdit = $('.brand-estee_edit', context);
      //do nothing for estee edit video overlays on mobile
      if (!$isEsteeEdit) {
        $('a.video-launcher').each(function() {
          var lnk = this.href;
          this.href = lnk.replace('https://www.youtube.com/embed/', 'https://www.youtube.com/watch?v=');
        });
      }
    } // attach
  };

  /**
   * youtube video overlay launcher
   */
  Drupal.behaviors.ELBMobileColorpox = {
    attach: function(context, settings) {
      $(document).bind('cbox_complete', function() {
        console.log('cbox complete');
      });
    } // attach
  };

  Drupal.behaviors.video_youtube = {
    attach: function(context) {
      $('.video-launcher-mobile', context).colorbox({iframe: true, width: '100%', height: '100%', className: 'colorbox__youtube'});
      $('.video-launcher-mobile', context).each(function(e) {
        var youtube_src = $(this).attr('href');
        youtube_src += '?autoplay=1';
        $(this).attr('href', youtube_src);
      });

      // deeplink to autoplay video
      var isAutoPlay = window.location.hash.substr(1);
      if (isAutoPlay === 'video-autoplay') {
        $('.hero-tout-mobile:not(.cancel-autoplay)', context).each(function() {
          $(this).find('.video-launcher-mobile').trigger('click');
        });
      }
    }// attach
  };

  /**
   * iframe boutique mobile alternative
   */
  Drupal.behaviors.iframe_boutique_mobile_alternative = {
    attach: function(context, settings) {
      // parse URL to load boutique
      // uses Purl.js
      var url_boutique = $.purl_url();
      if (url_boutique.fsegment(1) === 'boutique') {
        window.location = $('[data-boutique-id="' + url_boutique.fsegment(2) + '"]').attr('data-boutique-url');
      }
    } // attach
  };

  /**
   * Mobile specific search block interface behaviors})(jQuery);})(jQuery);
   */
  Drupal.behaviors.ELBPC_searchBlock = {
    attach: function(context, settings) {
      var $header = $('.page-header'),
          $block = $('.el-mobile-search-block', context),
          $dimOverlay = $('<div class="page-header__dim-overlay"></div>'),
          $siteContainer = $('.page-main, .pg_wrapper', context),
          alternate_color = $('.perlgem-mobile-search-block', $block).attr('data-alt-color') || '',
          sdSearchEnabled = $('.js-sd-search-gnav-input-field, .js-sd-discovery-gnav', $header).length > 0;

      if (sdSearchEnabled) {
        return;
      }

      if (alternate_color !== '') {
        $header.addClass(alternate_color);
      }
      // Close search when we click away
      $('html').on('click', function() {
        // if search is open, close it
        if (Drupal.ELB.ui.search_open) {
          $(document).trigger('searchClose');
        }
      });
      // Prevent header clicks from propagating to html tag
      $header.on('click', function(event) {
        event.stopPropagation();
      });
      // Search toggle on button click
      $header.on('click', '.page-utilities__search-button', function(event) {
        event.preventDefault();
        if (Drupal.ELB.ui.search_open) {
          // close if open
          $(document).trigger('searchClose');
        } else {
          // open if closed
          $(document).trigger('searchOpen');
        }
      });
      /**
       * React to searchOpen event
       */
      $(document).on('searchOpen', function(event) {
        Drupal.ELB.ui.search_open = true;
        // close all open nav
        $(document).trigger('navClose');
        // add custom search class onto header, with alt state
        $header.addClass(Drupal.ELB.ui.search_class);
        $('.page-utilities__search-button').addClass('active');
        // endeca search may happen here
        //remove input from search, reset typeahead
        $('input#search').val('');
        $('#typeahead-wrapper').hide();
        $block.show();
        $dimOverlay.prependTo($siteContainer);
        if (alternate_color !== '') {
          $dimOverlay.addClass(alternate_color);
        }
        $('#search').focus();
      });
      /**
       * React to searchClose event
       */
      $(document).on('searchClose', function(event) {
        Drupal.ELB.ui.search_open = false;
        // remove active header class
        $header.removeClass(Drupal.ELB.ui.search_class);
        $('.page-utilities__search-button').removeClass('active');
        $block.hide();
        $dimOverlay.remove();
      });
      // submit using our faux button
      $('.el-search-block__btn-submit').click(function(e) {
        e.preventDefault();
        $('#perlgem-search-form').submit();
      });
      $('.el-mobile-search-close').click(function(e) {
        e.preventDefault();
        $(document).trigger('searchClose');
      });
    } // attach
  };
})(jQuery);
